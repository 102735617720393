import { isEmpty, forEach } from "lodash";
import {
  DataQueryResponse,
  EntityAggregationResponse,
  EntityAggregationValue,
  EntityAggregationRequest,
  EntityAggregationSuggestion
} from "../../../services/api/types";
import {
  WidgetDataDTO,
  MetricResultData,
  MetricResultDTO,
  WidgetQuerySchema,
  SliceSpec,
  SelectorFilter,
  PostAgg,
  ExploreApiWarning,
  CohortEntityFilter,
  EntityOperation,
  FieldResult
} from "../../../services/api/explore";
import { TimeSeries, DataFrameType, DataFrame, timeseriesToDataFrame, logger, DataType } from "../../../core";
import { getNumericPredicateFromValues } from "../../../utils";

const MAX_BUCKETS = 5;

export const DISPLAY_NAME_TAG = "__display_name__";
export const AUTO_DS_INTERVAL = "auto";

export type BizEntityDataResultEntry = Omit<MetricResultData, "data"> & {
  data: DataFrame[];
};

export type BizEntityDataResult = {
  data: Record<string, BizEntityDataResultEntry>;
  compareConfigData: Record<string, BizEntityDataResultEntry>;
  postAggData: Record<string, BizEntityDataResultEntry>;
  postAggDeltaData: Record<string, BizEntityDataResultEntry>;
  postAggPerChangeData: Record<string, BizEntityDataResultEntry>;
  postAggTimeshiftData: Record<string, BizEntityDataResultEntry>;
  dataSize: number;
  compareDataSize: number;
  postAggDataSize: number;
  postAggDeltaDataSize: number;
  postAggPerChangeDataSize: number;
  postAggTimeshiftDataSize: number;
  dataQueryConfig: Record<string, any>;
  postAggEntityLookupData: Record<string, string>;
  dataDefinitionId: string;
  warnings: ExploreApiWarning[];
  fieldResult: FieldResult[];
  forecastAggData?: Record<string, BizEntityDataResultEntry>;
  forecastAggDeltaData?: Record<string, BizEntityDataResultEntry>;
  forecastAggPerChangeData?: Record<string, BizEntityDataResultEntry>;
  forecastLowerBoundData?: Record<string, BizEntityDataResultEntry>;
  forecastUpperBoundData?: Record<string, BizEntityDataResultEntry>;
  forecastAggDataSize?: number;
  forecastAggDeltaDataSize?: number;
  forecastPerChangeDataSize?: number;
  forecastLowerBoundDataSize?: number;
  forecastUpperBoundDataSize?: number;
  forecastEntityLookupData?: Record<string, string>;
};

export const bizEntityDataTransformer = (
  bizEntityresponses: Array<DataQueryResponse<WidgetDataDTO[]>>
): Array<DataQueryResponse<BizEntityDataResult[]>> => {
  const transformed = bizEntityresponses.map((bizEntityResponse): DataQueryResponse<BizEntityDataResult[]> => {
    const { data: bizEntityData, error } = bizEntityResponse;

    const transformedData: BizEntityDataResult[] = [];

    bizEntityData.forEach(bizEntityDatum => {
      const { metricResults } = bizEntityDatum;
      const fieldResults = bizEntityDatum.fieldResult;

      if (metricResults) {
        const transformedMetricResults = transformMetricResultDTOs(metricResults);
        transformedData.push(...transformedMetricResults);
      }

      if (fieldResults?.length > 0) {
        transformedData.push(transformFieldResultDTOs(fieldResults));
      }
    });

    return {
      data: transformedData,
      error
    };
  });

  return transformed;
};

export const getBizFieldPredicatesFromCohortFilters = (cohortAndEntityFilters: CohortEntityFilter[]) =>
  cohortAndEntityFilters.filter(ef => ef.fieldType === "bizEntityField").map(ef => ef.predicate);

export const transformFieldResultDTOs = (fieldResult: FieldResult[]) => {
  const fieldId = fieldResult[0].dataDefinitionId;
  const transformed: BizEntityDataResult = {
    fieldResult: fieldResult,
    dataDefinitionId: fieldId,
    data: undefined,
    compareConfigData: undefined,
    postAggData: undefined,
    postAggDeltaData: undefined,
    postAggPerChangeData: undefined,
    postAggTimeshiftData: undefined,
    dataSize: 0,
    compareDataSize: 0,
    postAggDataSize: 0,
    postAggDeltaDataSize: 0,
    postAggPerChangeDataSize: 0,
    postAggTimeshiftDataSize: 0,
    dataQueryConfig: undefined,
    postAggEntityLookupData: undefined,
    warnings: [],
    forecastAggData: undefined,
    forecastAggDeltaData: undefined,
    forecastPerChangeDataSize: undefined,
    forecastAggDataSize: 0,
    forecastAggPerChangeData: undefined,
    forecastLowerBoundDataSize: 0,
    forecastLowerBoundData: undefined,
    forecastUpperBoundData: undefined,
    forecastUpperBoundDataSize: undefined,
    forecastEntityLookupData: {}
  };
  return transformed;
};

export const transformMetricResultDTOs = (metricResults: MetricResultDTO[]) => {
  const transformedData: BizEntityDataResult[] = [];

  metricResults.forEach(tsResult => {
    const {
      data,
      dataDefinitionId,
      compareConfigData,
      dataQueryConfig,
      postAggResult,
      forecastResult,
      warnings = [],
      fieldResult
    } = tsResult;

    const {
      data: postAggData = {},
      entityLookupData: postAggEntityLookupData = {},
      percentageChangeData: postAggPercentChangeData = {},
      deltaData: postAggDeltaData = {},
      timeShiftedData: postAggTimeShiftData = {}
    } = postAggResult || {};

    const {
      data: forecastData = {},
      delta: forecastDeltaData = {},
      entityLookupData: forecastEntityLookupData = {},
      percentageChangeData: forecastPercentageChangeData = {},
      lowerBoundData: forecastLowerBoundData = {},
      upperBoundData: forecastUpperBoundData = {}
    } = forecastResult || {};

    const processTimeSeries = (ts: TimeSeries, isCompareSeries: boolean, dfType: DataFrameType): DataFrame => {
      const { tags = {}, eTags = tags } = ts;

      // Delete the __display_name__ tag from tags and enriched tags
      delete tags[DISPLAY_NAME_TAG];
      delete eTags[DISPLAY_NAME_TAG];

      const dataType: DataFrame["dataType"] = "NA";
      const subType: DataFrame["subType"] = "not_set";

      try {
        const df = timeseriesToDataFrame(ts, dataType, subType);
        df.meta = {
          isCompareDF: isCompareSeries,
          dataDefinitionId,
          dfType
        };

        return df;
      } catch (err) {
        logger.info(`Error parsing prom response with dataDefId ${dataDefinitionId}`, (err as Error).message);
      }
      return null;
    };

    const processDataJson = (
      json: Record<string, MetricResultData>,
      isCompareData: boolean,
      dfType: DataFrameType
    ): [Record<string, BizEntityDataResultEntry>, number] => {
      const processedJson: Record<string, BizEntityDataResultEntry> = {};
      let size = 0;

      // This will handle stringified JSON (happens sometimes)
      json = typeof json === "string" ? JSON.parse(json) : json;

      forEach(json, (md, key) => {
        const { data: tsArr, preLimitSelectionCount, schema, seasonSecs } = md;
        size += tsArr.length;

        const dataframes = tsArr
          .map(t => {
            const ts = t as TimeSeries;
            return processTimeSeries(ts, isCompareData, dfType);
          })
          .filter(df => !isEmpty(df));

        processedJson[key] = {
          data: dataframes,
          schema,
          preLimitSelectionCount,
          seasonSecs
        };
      });
      return [processedJson, size];
    };

    const [pData, dataSize] = processDataJson(data, false, DataFrameType.original);
    const [pCompareData, compareDataSize] = processDataJson(compareConfigData, true, DataFrameType.compare);
    const [pPostAggData, paDataSize] = processDataJson(postAggData, false, DataFrameType.aggregated);
    const [pPostAggTimeShiftData, paTimeshiftDataSize] = processDataJson(
      postAggTimeShiftData,
      false,
      DataFrameType.aggregatedCompare
    );

    const [pPostAggDeltaData, paDeltaDataSize] = processDataJson(postAggDeltaData, false, DataFrameType.aggregated);
    const [pPostAggPercentChangeData, paPerChangeDataSize] = processDataJson(
      postAggPercentChangeData,
      false,
      DataFrameType.aggregatedPercentage
    );

    const [fData, fDataSize] = processDataJson(forecastData, false, DataFrameType.aggregated);
    const [fDeltaData, fDeltaDataSize] = processDataJson(forecastDeltaData, false, DataFrameType.aggregated);
    const [fPercentageChangeData, fPerChangeDataSize] = processDataJson(
      forecastPercentageChangeData,
      false,
      DataFrameType.aggregatedPercentage
    );
    const [fLowerBoundData, fLowerBoundDataSize] = processDataJson(
      forecastLowerBoundData,
      false,
      DataFrameType.original
    );
    const [fUpperBoundData, fUpperBoundDataSize] = processDataJson(
      forecastUpperBoundData,
      false,
      DataFrameType.original
    );

    transformedData.push({
      data: pData,
      compareConfigData: pCompareData,
      postAggData: pPostAggData,
      postAggDeltaData: pPostAggDeltaData,
      postAggPerChangeData: pPostAggPercentChangeData,
      postAggTimeshiftData: pPostAggTimeShiftData,
      dataSize,
      compareDataSize,
      postAggDataSize: paDataSize,
      postAggDeltaDataSize: paDeltaDataSize,
      postAggPerChangeDataSize: paPerChangeDataSize,
      postAggTimeshiftDataSize: paTimeshiftDataSize,
      dataQueryConfig,
      postAggEntityLookupData,
      dataDefinitionId,
      warnings,
      fieldResult,
      forecastAggData: fData,
      forecastAggDeltaData: fDeltaData,
      forecastAggDeltaDataSize: fDeltaDataSize,
      forecastPerChangeDataSize: fPerChangeDataSize,
      forecastAggDataSize: fDataSize,
      forecastAggPerChangeData: fPercentageChangeData,
      forecastLowerBoundDataSize: fLowerBoundDataSize,
      forecastLowerBoundData: fLowerBoundData,
      forecastUpperBoundData: fUpperBoundData,
      forecastUpperBoundDataSize: fUpperBoundDataSize,
      forecastEntityLookupData
    });
  });

  return transformedData;
};

export const getSliceSpecFromQuerySchema = (
  querySchema: WidgetQuerySchema[],
  filters?: SelectorFilter[][],
  postAggs?: PostAgg[]
): SliceSpec[] =>
  querySchema.map((qs, idx) => {
    const { metricId, sliceSet } = qs;
    const sFilters = filters ? filters[idx] || [] : [];
    const sPostAgg = postAggs ? postAggs[idx] || null : null;

    const sliceSpec: SliceSpec = {
      metricId,
      selectorSpec: {
        filters: sFilters
      },
      sliceSet
    };

    if (sPostAgg) {
      sliceSpec.postAgg = sPostAgg;
    }

    return sliceSpec;
  });

function handleNumericDataType(values: string[]) {
  const [min, max] = values;
  const { op, value } = getNumericPredicateFromValues(min, max);

  return {
    values: value,
    operator: op
  };
}

function handleStringDataType(values: string[]) {
  let finalValues: string | string[] = [...values];
  let operator: EntityOperation = "eq";

  if (values.length === 1) {
    finalValues = values[0];
    operator = "eq";
  } else {
    operator = "in";
  }

  return {
    values: finalValues,
    operator
  };
}

function handleDateDataType(values: string[]) {
  const finalValues: string | string[] = values.slice(0, 2);
  const operator: EntityOperation = "range";

  return {
    values: finalValues,
    operator
  };
}

export function getFinalValuesAndOperatorForDataType(values: string[], dataType: DataType | "NA"): PredicateValue {
  if (dataType === "LONG" || dataType === "DOUBLE") {
    return handleNumericDataType(values);
  }

  if (dataType === "STRING") {
    return handleStringDataType(values);
  }

  if (dataType === "DATE" || dataType === "DATETIME") {
    return handleDateDataType(values);
  }

  const finalValues = [...values];
  const operator: EntityOperation = "eq";

  return {
    values: finalValues,
    operator
  };
}

export const processAggregationsResponse = (aggResponse: EntityAggregationResponse, error: string, name: string) => {
  const result = {
    aggValues: [] as EntityAggregationValue[],
    error: ""
  };

  if (error) {
    result.error = error;
  } else {
    const resultAggregations = aggResponse?.aggregations?.[name];
    if (resultAggregations) {
      let aggValues = Object.values(resultAggregations)[0]?.buckets || [];
      aggValues = aggValues.filter(({ count }) => !isEmpty(count));
      result.aggValues = aggValues;
    } else {
      result.error = "No aggregations found";
    }
  }
  return result;
};

export const getAggregationRequestPayload = (
  aggSuggestion: EntityAggregationSuggestion
): [EntityAggregationRequest, boolean] => {
  const { aggregationMeta, suggestedAggregationOperator, field } = aggSuggestion || {};

  // this.kind = kind;

  let isValid = false;

  const aggregator = Object.keys(suggestedAggregationOperator || {})[0];

  const aggregationRequest: EntityAggregationRequest = {
    field,
    name: `${field}`
  };

  if (aggregator) {
    const { cardinality, stats } = aggregationMeta || {};

    const isDateHistogram = aggregator.toLowerCase().includes("datehistogram");

    if (isDateHistogram) {
      aggregationRequest[aggregator] = {
        calendarInterval: "quarter"
      };
    } else if (stats) {
      // Histogram case
      const { min = 0, max = 10000 } = stats;
      const buckets = getBuckets(min, max, MAX_BUCKETS + 1);
      const ranges: Array<Record<string, any>> = [];
      buckets.forEach(bucket => {
        const { min, max } = bucket;
        const minExists = !isNaN(min);
        const maxExists = !isNaN(max);
        if (minExists && maxExists) {
          ranges.push({
            key: "mid",
            from: min,
            to: max
          });
        } else if (minExists) {
          ranges.push({
            key: "high",
            from: min,
            unboundedFrom: true
          });
        } else if (maxExists) {
          ranges.push({
            key: "low",
            to: max,
            unboundedTo: true
          });
        }
      });
      aggregationRequest["range"] = {
        ranges
      };
      isValid = ranges.length > 0;
    } else {
      let size = parseInt(cardinality?.value, 10);
      size = isNaN(size) || !size ? 4 : size;
      aggregationRequest[aggregator] = {
        size
      };
    }
    isValid = true;
  }

  return [aggregationRequest, isValid];
};

type PredicateValue = {
  values: string[] | string;
  operator: EntityOperation;
};

type Bucket = {
  min: number;
  max: number;
};

export const getBuckets = (min: number, max: number, numBuckets: number) => {
  // Set min's tier to 1 if the value is 0, else the log logic will result in -Infinity
  const tierMin = min ? Math.floor(Math.log10(min)) : 1;

  const valueDivisor = Math.pow(10, tierMin - 1);
  const minQuotient = min / valueDivisor;
  const maxQuotient = max / valueDivisor;

  const minQuotient5 = Math.floor(minQuotient / 5) * 5;
  const maxQuotient5 = Math.ceil(maxQuotient / 5) * 5;

  const start = minQuotient5 * valueDivisor;
  const end = maxQuotient5 * valueDivisor;

  const possibleBucketSize = (end - start) / numBuckets;
  const bucketSizeTier = Math.floor(Math.log10(possibleBucketSize));
  const bucketSizeDivisor = Math.pow(10, bucketSizeTier - 1);
  const bucketSizeQuotient = possibleBucketSize / bucketSizeDivisor;
  const bucketSizeQuotient5 = Math.ceil(bucketSizeQuotient / 5) * 5;
  const bucketSize = bucketSizeQuotient5 * bucketSizeDivisor;

  let buckets: Bucket[] = [];

  let rangeStart = start;

  for (let i = 0; i < numBuckets; i++) {
    const rangeEnd = i === numBuckets - 1 ? NaN : rangeStart + bucketSize;
    buckets.push({
      max: rangeEnd,
      min: rangeStart
    });
    rangeStart = rangeEnd;
  }

  buckets = buckets.filter(b => b.min <= max);

  return buckets;
};
