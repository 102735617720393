import { parse } from "query-string";
import { CurrencyType, generateId } from "@inception/ui";
import { UITenantConfig, VerticalConfig } from "./platform/core";

//const BASE_URL = process.env.REACT_APP_APPTUIT_UI_SERVICE_URL; // Base Url to connect to. All api's will append to its url.
const BASE_PATH = process.env.REACT_APP_APPTUIT_UI_PATH; // Base host path to connect for all api's. Ex: /apptuit
const API_GATEWAY_URL = process.env.REACT_APP_BICYCLE_GATEWAY_URL; // Base host path to connect for all api's. Ex: /apptuit
const DATA_SOURCE_URL = `${BASE_PATH}/api/datasources`; // Endpoint to connect to apptuit-grafana datasource proxy.
const DASHBOARD_URL = `${BASE_PATH}/api/inc/dashboards`; // Endpoint to connect to apptuit-grafana dashboards proxy.
const GET_DATA_SOURCE_LIST = `${BASE_PATH}/api/inc/datasources`; // Endpoint to get all datasources
const DEFAULT_DATASOURCE = process.env.REACT_APP_APPTUIT_DS_NAME || "Apptuit"; // Default datasource to connect.
const PROMETHEUS_DATASOURCE = process.env.REACT_APP_PROMETHEUS_DS_NAME || "Apptuit-PromQL (kdev-anom-qs)"; // Default datasource to connect.
const API_DOMAIN_URL = process.env.REACT_APP_API_DOMAIN_PATH;
const GRAFANA_ORG_HEADER = "x-grafana-org-id";
const INC_REQUEST_ID_HEADER = "X-Cloud-Trace-Context";
const INC_REFERRER_HEADER = "x-cloud-referrer";
const DEFAULT_EXPLORE_DS_NAME = "explore";
const DEFAULT_OP10ZE_DS_NAME = "operationalize";
const CACHE_REFRESH_TIME = 5; // Time to refresh the Apollo Client Cache in minutes.
const BRAND_PREFIX = "Bicycle";
const BRAND_EMAIL_DOMAIN = "bicycle.io";
const DEFAULT_CURRENCY_TYPE: CurrencyType = "USD";
const MAX_RETRIES = 3;
const CUSTOM_CONFIG_TYPE = "c_custom_config";
const DEMO_MONKEY_CONFIG_NAME = "demo_monkey_configuration";
const DEMO_CONFIG_TYPE = "c_demo_monkey_config";
const DEFAULT_DEMO_MONKEY_VERTICALS = ["Retail", "Shopify"];
const DEMO_MONKEY_EXCLUDE_ROUTES = ["admin", "configuration", "demo-selector"];
const SMOKE_TEST_USER_EMAIL = "agilx.gcp.2@gmail.com";
const INC_VISITOR_ID_HEADER = "visitor-id";
const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;

type AppConfig = {
  baseUrl: string;
  basePath: string;
  apiGatewayUrl: string;
  datasourceUrl: string;
  dashboardUrl: string;
  getDatasourcesList: string;
  defaultDatasource: string;
  prometheusDatasource: string;
  grafanaOrgHeader: string;
  apiDomainUrl: string;
  incRequestIdHeader: string;
  incReferrerHeader: string;
  incVisitorIdHeader: string;
  defaultExploreDsName: string;
  defaultOp10zeDsName: string;
  cacheRefreshTime: number;
  branding: {
    prefix: string;
    emailDomain: string;
  };
  defaultCurrencyType: CurrencyType;
  tenantConfig: UITenantConfig;
  verticalConfig: VerticalConfig;
  maxRetries: number;
  demoMonkeyConfigType: string;
  demoMonkeyConfigName: string;
  env: string;
  retryHttpErrorCodes: number[];
  highCardinalityLimit: number;
  customConfigType: string;
  defaultDemoMonkeyVerticals: string[];
  demoMonkeyRoutesToExclude: string[];
  smokeTestUserEmail: string;
  isProductionEnv: boolean;
  defaultSuggestionsLimit: number;
  anomShareId: string;
  visitorId: string;
  allUseCaseOrVerticalColor: string;
  sentryDsn: string;
  logPrefixes: {
    fatalError: string;
    error: string;
    info: string;
    trace: string;
    debug: string;
    warn: string;
    network: string;
  };
};

type AppData = {
  build: {
    appEnv: string;
    env: string; // "preview-ione", "preview-dev", "ione", "dev", "bigbasket"
    sha: string;
    version: string;
    imageVersion: string;
    releaseType: "preview-ione" | "preview-dev" | "ione" | "dev";
  };
};

const appConfig: AppConfig = {
  baseUrl: "",
  basePath: BASE_PATH,
  apiGatewayUrl: API_GATEWAY_URL,
  datasourceUrl: DATA_SOURCE_URL,
  getDatasourcesList: GET_DATA_SOURCE_LIST,
  defaultDatasource: DEFAULT_DATASOURCE,
  prometheusDatasource: PROMETHEUS_DATASOURCE,
  apiDomainUrl: API_DOMAIN_URL,
  dashboardUrl: DASHBOARD_URL,
  env: process.env.NODE_ENV,
  grafanaOrgHeader: GRAFANA_ORG_HEADER,
  incRequestIdHeader: INC_REQUEST_ID_HEADER,
  incReferrerHeader: INC_REFERRER_HEADER,
  incVisitorIdHeader: INC_VISITOR_ID_HEADER,
  defaultExploreDsName: DEFAULT_EXPLORE_DS_NAME,
  defaultOp10zeDsName: DEFAULT_OP10ZE_DS_NAME,
  branding: {
    prefix: BRAND_PREFIX,
    emailDomain: BRAND_EMAIL_DOMAIN
  },
  cacheRefreshTime: CACHE_REFRESH_TIME,
  defaultCurrencyType: DEFAULT_CURRENCY_TYPE,
  tenantConfig: {} as UITenantConfig,
  verticalConfig: {} as VerticalConfig,
  maxRetries: MAX_RETRIES,
  retryHttpErrorCodes: [503],
  highCardinalityLimit: 100000,
  customConfigType: CUSTOM_CONFIG_TYPE,
  demoMonkeyConfigType: DEMO_CONFIG_TYPE,
  demoMonkeyConfigName: DEMO_MONKEY_CONFIG_NAME,
  defaultDemoMonkeyVerticals: DEFAULT_DEMO_MONKEY_VERTICALS,
  demoMonkeyRoutesToExclude: DEMO_MONKEY_EXCLUDE_ROUTES,
  smokeTestUserEmail: SMOKE_TEST_USER_EMAIL,
  isProductionEnv: process.env.REACT_APP_ENVIRONMENT === "production",
  defaultSuggestionsLimit: 5,
  anomShareId: "",
  visitorId: "",
  allUseCaseOrVerticalColor: "#1A7FEA",
  sentryDsn: SENTRY_DSN,
  logPrefixes: {
    fatalError: "FATAL",
    error: "ERROR",
    info: "INFO",
    trace: "TRACE",
    debug: "DEBUG",
    warn: "WARN",
    network: "NETWORK"
  }
};

if (window?.location?.search) {
  const { search } = window.location;
  const paramsStr = search.slice(1); // remove ? from the string
  const paramsObj = parse(paramsStr);
  if (paramsObj.shareId) {
    appConfig.anomShareId = paramsObj.shareId as string;
    let visitorId = localStorage.getItem("visitorId") || "";
    if (!visitorId) {
      visitorId = generateId();
      localStorage.setItem("visitorId", visitorId);
    }
    appConfig.visitorId = visitorId;
  }
}

export const appData = ((window as any).bootData || {}) as AppData;

// Temporary change, need to figure out a better way to add build information for big basket
if (window?.location) {
  const { host } = window.location;
  const execArray = /app.(\w+).bicycle.ai/gm.exec(host);
  if (execArray?.[1]) {
    appData.build.env = execArray[1];
  }
}

//uncomment this if testing for preview
// eslint-disable-next-line no-constant-condition
//if (1) {
//  appData.build.releaseType = "preview-ione";
//}

export const isPreviewRelease =
  appData?.build?.releaseType === "preview-ione" || appData?.build?.releaseType === "preview-dev";
export const isIoneRelease = appData?.build?.releaseType === "ione";
export const isDevRelease = appData?.build?.releaseType === "dev";
export const isPreviewDevRelease = appData?.build?.releaseType === "preview-dev";
export const isPreviewIoneRelease = appData?.build?.releaseType === "preview-ione";

export default appConfig;
