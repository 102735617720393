import { each, first, flatten, groupBy, isEmpty, keys, uniq, values } from "lodash";
import { getFormattedDateTime, IncDateTimeFormat } from "@inception/ui";
import { EIncident } from "../services/api/explore";
import {
  dateTime,
  IDTAGS,
  Incident,
  IncidentSummary,
  IncidentTimeSeries,
  IncludedIncident,
  MonitoredQuery,
  shouldExcludeTag,
  toDuration
} from "../core";
import { ScheduledQueryConfig } from "../services/api/Outcome";

const IncidentStatus = {
  active: "ACTIVE",
  closed: "CLOSED"
};

export class IncidentModel {
  id: string;
  name: string;
  status: string;
  severity: any;
  start: number;
  windowStartSecs: number;
  end: number;
  queryId: string;
  missed: boolean;
  tags: any;
  incidentFeedback: any;
  summary: IncidentSummary | null;
  query: MonitoredQuery;
  incidentSummaryModel: IncidentSummaryModel;

  isExpanded: boolean;

  constructor(mq: MonitoredQuery, incident?: Incident | EIncident) {
    incident = incident || ({} as Incident);

    this.query = mq;
    this.id = incident.id || "";
    this.name = incident.name || "";
    this.status = incident.status || "";
    this.start = incident.windowStartSecs || 0;
    this.windowStartSecs = incident.windowStartSecs || 0;
    this.end = incident.incidentCloseWindowStartSecs || 0;
    this.severity = incident.severity || "";
    this.queryId = incident.queryId || "";
    this.missed = incident.missed || false;
    this.tags = incident.tags || [];

    this.start = this.start * 1000;
    this.end = this.end !== -1 ? this.end * 1000 : this.end;

    this.incidentFeedback = incident.incidentFeedback || {
      feedbackValue: "NONE"
    };

    if (incident.summary) {
      try {
        this.summary = typeof incident.summary === "object" ? incident.summary || {} : JSON.parse(incident.summary);
        this.incidentSummaryModel = new IncidentSummaryModel(
          this.query.id,
          this.id,
          this.windowStartSecs,
          this.end,
          this.status,
          this.summary
        );
      } catch (err) {
        this.summary = null;
      }
    }
  }

  getIdTags(): string[] {
    const mqProperties = this.query?.properties || {};
    const idTags = mqProperties[IDTAGS] || "";
    return isEmpty(idTags) ? [] : idTags.split(",").map((idTag: string) => idTag.trim());
  }

  getApptuitIncidentUrl(start?: number, end?: number) {
    const iStart = start ? start : this.start - 5 * 60 * 1000;
    const iEnd = end ? end : this.isActive() ? "now" : this.end;
    return (
      `/apptuit/monitoring/list?query=${this.query.id}&from=${iStart}&to=${iEnd}` +
      `&incident=${this.id}&mode=view&labels=All`
    );
  }

  getIncIncidentUrl(
    outcomeId: string,
    rootApis?: string[],
    sqConfigs: ScheduledQueryConfig[] = [],
    start?: number,
    end?: number
  ) {
    const iStart = start ? start : this.start - 5 * 60 * 1000;
    const iEnd = end ? end : this.isActive() ? Date.now() : this.end;
    const config = sqConfigs.find(config => config.metricName === this.query?.query?.metric);
    const context = {
      context: {
        kind: "bo",
        metricName: this.query?.query?.metric,
        queryType: "traces",
        type: "business",
        query: config?.sqPayload?.query,
        minStep: this.query?.query.dsIntervalSec || 60
      },
      entities: [] as string[],
      filterQuery: "",
      tags: {},
      userServices:
        rootApis?.map(id => ({
          id,
          type: "i_api"
        })) || [],
      from: Math.floor(iStart / 1000), // sending time in seconds
      to: Math.floor(iEnd / 1000),
      entityType: "i_bizoutcome"
    };
    return `/triage?context=${JSON.stringify(context)}&tab=corelated&type=outcome&query=${this.query.id}&incident=${this.id}&entityId=${outcomeId}&from=${iStart}&to=${iEnd}`;
    //return `/outcomes/${outcomeId}/incident?query=${this.query.id}&incident=${this.id}&from=${iStart}&to=${iEnd}`;
  }

  getStartMillis() {
    return this.start;
  }

  getEndMillis(end?: number) {
    if (this.isActive()) {
      end = end || new Date().getTime();
    } else {
      end = this.end;
    }
    return end;
  }

  getStart() {
    const start = this.getStartMillis();
    return this.getDateTimeStr(start);
  }

  getEnd(end?: number): string {
    end = this.getEndMillis(end);
    return this.getDateTimeStr(end);
  }

  getDurationHumanized(end?: number): string {
    if (this.isActive()) {
      end = end || new Date().getTime();
    } else {
      end = this.end;
    }
    return toDuration(dateTime(end).diff(dateTime(this.start), "minutes"), "minutes").humanize();
  }

  isActive(): boolean {
    return this.status === IncidentStatus.active;
  }

  isClosed(): boolean {
    return this.status === IncidentStatus.closed;
  }

  private getDateTimeStr(millis: number) {
    return getFormattedDateTime(millis, IncDateTimeFormat.minimal, {
      withSeconds: true
    });
  }
}

export class IncidentSummaryModel {
  mqId: string;
  incidentId: string;
  start: number;
  end: number;
  summary: IncidentSummary;
  invalid: boolean;
  status: string;

  tsList: IncidentTimeSeries[];
  mtsList: Record<string, IncludedIncident>;

  constructor(
    mqId: string,
    incidentId: string,
    start: number,
    end: number,
    status: string,
    summary: string | IncidentSummary
  ) {
    this.mqId = mqId;
    this.incidentId = incidentId;
    this.start = start;
    this.end = end;
    this.status = status;

    try {
      this.summary = typeof summary === "object" ? summary || {} : JSON.parse(summary || "");
      this.process();
      this.invalid = false;
    } catch (e) {
      this.invalid = true;
    }
  }

  findMTS(mtsId: string): IncludedIncident {
    return this.mtsList[mtsId];
  }

  findSeries(tags: Record<string, string>): IncludedIncident[] {
    const matches: IncludedIncident[] = [];
    each(this.mtsList, (i: IncludedIncident) => {
      if (this.tagsMatch(tags, i.series.tags)) {
        matches.push(i);
      }
    });
    return matches;
  }

  getIncludedIncidentsListByKeyValue(key: string, value: string) {
    const includedIncidentWithMatch: IncludedIncident[] = [];
    values(this.mtsList).forEach(x => {
      if ((x.series?.tags || {})[key] === value) {
        includedIncidentWithMatch.push(x);
      }
    });
    return includedIncidentWithMatch;
  }

  getStartTimeOfSeries(key: string, value: string): number {
    const includedIncidentWithMatch = this.getIncludedIncidentsListByKeyValue(key, value);

    return Math.min(
      ...includedIncidentWithMatch.map(x =>
        Math.min(...x.ranges.filter(r => r.windowStartTS !== -1).map(r => r.windowStartTS))
      )
    );
  }

  getEndTimeOfSeries(key: string, value: string): number {
    const includedIncidentWithMatch = this.getIncludedIncidentsListByKeyValue(key, value);
    const endTimeList = includedIncidentWithMatch.map(x => x.ranges.map(x => x.end));
    const flattenedList = flatten(endTimeList);
    if (flattenedList.includes(-1)) {
      return -1;
    } else {
      return Math.max(...flattenedList);
    }
  }

  getUniqueTagKeys() {
    const mtsList = values(this.mtsList);
    const uniqueTags: string[] = [];
    mtsList.forEach(x => {
      // console.log('x is ', x.series.tags);
      keys(x.series.tags).forEach(tag => {
        if (!shouldExcludeTag(tag) && !uniqueTags.includes(tag)) {
          uniqueTags.push(tag);
        }
      });
    });
    return uniqueTags;
  }

  getUniqueTagValues(tagKey: string) {
    const mtsList = values(this.mtsList);
    const uniqueTagValues: string[] = [];
    mtsList.forEach(x => {
      // console.log('x is ', x.series.tags);
      const tagValue = x.series.tags[tagKey];
      uniqueTagValues.push(tagValue);
    });
    return uniq(uniqueTagValues.filter(Boolean));
  }

  groupTsByTag(tag: string): Record<string, IncludedIncident[]> {
    const mtsList = values(this.mtsList);
    return groupBy(mtsList, mts => (mts.series.tags || {})[tag]);
  }

  protected process() {
    this.tsList = this.summary.tsList;
    this.mtsList = {};

    const { start, end } = first(this.summary.ranges);
    this.start = start;
    this.end = end;

    const processIncident = (iIncident: IncludedIncident) => {
      iIncident.series = this.tsList[iIncident.tsi];
      this.mtsList[iIncident.mtsId] = iIncident;
    };

    this.summary.includes.map((iIncident: IncludedIncident) => {
      if (!iIncident.includes.length) {
        processIncident(iIncident);
      } else {
        iIncident.includes.map((iIncident: IncludedIncident) => {
          processIncident(iIncident);
          return iIncident;
        });
      }
      return iIncident;
    });
  }

  protected tagsMatch(source: Record<string, string>, target: Record<string, string>): boolean {
    let matches = false;
    each(source, (tagv, tagk) => {
      matches = target[tagk] === tagv;
    });
    return matches;
  }
}

export const DEVIATION_TAG = "Deviation";
