import { LogicalOperation } from "../../../../core";
import { DemoDataGenInfo, TimeObj } from "../../explore";
import { Op10zeSetupRequest } from "./operationalise-api";
import { QueryWindowTimeRange } from "./OperationaliseV2Types";
import { OpThresholdSeasonality } from "./thresholds";

export interface MonitoredDataSchema {
  series: MonitoredSeriesDef[];
  entityLookupData: Record<string, string>;
  allSeriesCount: number;
}

export interface MonitoredSeriesDef {
  timeSeries: TimeSeriesDef;
  freq: MonitoredFreq;
}

export interface MonitoredFreq {
  lookBack: TimeObj;
  frequency: TimeObj;
  seasonality: OpThresholdSeasonality;
}

export interface TimeSeriesDef {
  metricName: string;
  label: Label[];
}

export interface FilterLabel {
  key: string;
  operator: LogicalOperation;
  value: string[];
}

export interface OpSchemaSearch {
  timeRange: QueryWindowTimeRange;
  filters: FilterLabel[];
  limit: number;
  // one configSrc for existing op or opsetup
  opSetupRequest?: Op10zeSetupRequest;
  existingOp?: ExistingOp;

  projection: OpSchemaProjection;
  demoDataGenInfo?: DemoDataGenInfo;
}

export enum OpSchemaProjection {
  NONE = "NONE",
  ALL = "ALL",
  SERIES = "SERIES",
  COUNT = "COUNT"
}

export interface ExistingOp {
  opId: string;
  // oneof versionInfo
  version?: number;
  incidentId?: string;
  simulationId?: string;
}

export interface Label {
  name: string;
  value: string;
}
