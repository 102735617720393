//this file should never be imported in event/entity types

import { IncFaIconName } from "@inception/ui";
import { FieldPrimType, FieldSubType } from "../../../core/data/types/DataTypes";
import { EntityPropertyValue } from "../../../core/data/types/EntityQueryTypes";
import { FieldTransformationConfig, TransformType } from "../../../transformation/transform-config/TransformConfig";

import {
  ConfiguredConnectorStream,
  PreviewDataObj,
  SaveBizSystemConnectorRequestV2,
  SourceFieldMapping,
  SourceSchema,
  TypeRef
} from "../entity-mapping/types";

import {
  FieldTypeCategory,
  SaveSourceConfig,
  SaveSourceConfigureConnection,
  SourceFilter,
  SourceType,
  UserServiceFieldDef,
  UserServiceMappingRule,
  UserServiceNameResponse,
  UserServiceNamingRule
} from "../event-mapping/types";
import { DimensionV2 } from "../onboarding";
import { PlanExecutionResponse, QuestionFragment } from "../chat";

export type DiscoverSourceConfig = {
  name: string;
  configuredConnectorStreamId?: string;
  connectionId?: string;
  sourceType: SourceType;
  additionalProperties?: Record<string, string>;
  scheduleJson?: string;
  configuredConnection?: SaveSourceConfigureConnection;
};

export type ConnectedEdges = {
  name: string[];
};

export type DiscoverUserService = {
  filters: SourceFilter[];
  streamId: string;
  userServiceName: string;
};

export type DiscoverEventsResponse = {
  entitySources: DiscoverSourceConfig[] | AutoDiscoverySourceSummary[];
  eventSources: DiscoverSourceConfig[] | AutoDiscoverySourceSummary[];
  streamToUserService: Record<string, ConnectedEdges>;
  userServiceToEntity: Record<string, ConnectedEdges>;
  userServices: DiscoverUserService[];
  entityTypeToTypeReference: Record<string, TypeRef>;
};

export enum MappingType {
  MAPPED = "MAPPED",
  UNMAPPED = "UNMAPPED",
  BICYCLE_RECOMMENDATION = "BICYCLE_RECOMMENDATION",
  USER_MAPPING = "USER_MAPPING",
  MODIFIED_RECOMMENDATION = "MODIFIED_RECOMMENDATION",
  RECOMMENDED = "RECOMMENDED"
}

export type DiscoveredCustomFieldDef = {
  name: string;
  fieldJsonPath: string; //"$.data.items"
  dataType: FieldPrimType;
  subtype: FieldSubType;
  //  repeated schema.types.PropertyValue sampleValues = 5;
  selected: boolean;
  cardinalityResponse?: {
    count: string;
    ratio: number;
    value: string;
  };
  statsResponse?: {
    count: number;
    min: number;
    max: number;
    avg: number;
    sum: number;
  };
  customObjectType: string;
  fieldCategory: FieldTypeCategory;
  predefinedFieldType: string;
  dataTransformations: Array<FieldTransformationConfig<TransformType>>;
  distribution?: Distribution;
  verticalContextField?: VerticalEventField;
  mappingType: MappingType;
  isCommonField: boolean;
  fieldDef: DiscoveredFieldDef[]; //(-> fieldJsonPath: "@.name")
  entityDef: DiscoveredEntityFieldDef[];
  isVirtual?: boolean;
};

export type DiscoveredFieldDef = {
  cardinalityResponse?: {
    count: string;
    ratio: number;
    value: string;
  };
  statsResponse?: {
    count: number;
    min: number;
    max: number;
    avg: number;
    sum: number;
  };
  dataType: FieldPrimType;
  entityType: string;
  fieldJsonPath: string;
  jsonPath?: string;
  isFieldEntity: boolean;
  isFieldUnique: boolean;
  name: string;
  sampleValues: Array<Record<keyof EntityPropertyValue, string>>;
  selected: boolean;
  subtype: FieldSubType;
  fieldCategory: FieldTypeCategory;
  predefinedFieldType: string;
  dataTransformations: Array<FieldTransformationConfig<TransformType>>;
  distribution?: Distribution;
  compositeFields?: DiscoveredFieldDef[];
  verticalContextField?: VerticalEventField;
  mappingType: MappingType;
  isCommonField: boolean;
  isVirtual?: boolean;
  dimension?: DimensionV2;
};

export interface Distribution {
  valueDistribution: ValueDistribution[];
}

export interface ValueDistribution {
  value: EntityPropertyValue;
  share: number;
}

export type DiscoverUserServiceDef = {
  jsonPathToFields: Record<string, DiscoveredFieldDef>;
  previewData: PreviewDataObj;
  userServiceSummary: {
    filters: SourceFilter[];
    streams: SaveSourceConfig[];
    userServiceName: string;
  };
};

export type DiscoverEventDetailsResponse = {
  userServiceDef: DiscoverUserServiceDef;
};

export type DiscoverSourceDetailsResponse = {
  fieldDefs: Record<string, DiscoveredFieldDef>;
  previewData: PreviewDataObj;
  userServices: string[];
};

export enum DiscoveryStatus {
  UNKNOWN = "UNKNOWN",
  STARTED = "STARTED",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETE = "COMPLETE",
  ERROR = "ERROR",
  CANCELLED = "CANCELLED",
  DISCOVERED = "DISCOVERED"
}

export interface DiscoveryStatusResponse {
  message: string;
  details: string;
}

export interface TraceInfo {
  traceId: string; //uuid for every request
}

export interface VerticalEntityField {
  id: string;
  defaultName: string; //originalName
  overrideName: string; //displayName
  kind: FieldPrimType;
  aliases: string[];
  description: string;
}

export interface VerticalEntityType {
  id: string;
  defaultName: string;
  overrideName: string;
  entityFields: VerticalEntityField[];
  aliases: string[];
  icon?: string;
  description?: string;
  possibleEventFieldNames?: string[];
}

export interface VerticalEventField {
  id: string;
  defaultName: string;
  overrideName: string;
  aliases: string[];
  description: string;
  category: FieldTypeCategory;
  kind?: FieldPrimType;
  subType?: FieldSubType;
  entityId?: string;
}

export interface VerticalEventType {
  id: string;
  defaultName: string;
  overrideName: string;
  eventFields: VerticalEventField[];
  aliases: string[];
  icon: string;
  description: string;
  entityId: string;
}

export interface Vertical {
  id: string;
  name: string;
  icon: string;
}

export interface VerticalsResponse {
  verticals: Vertical[];
}

export interface VerticalContextResponse {
  id: string;
  name: string;
  entities: VerticalEntityType[];
  events: VerticalEventType[];
}

export interface VerticalContextRequest {
  verticalName: string;
}

export interface InitiateAutoDiscoveryUIRequest {
  requestId: string;
  traceInfo?: TraceInfo;
  //eventSourceConnection: SaveSourceConfig;
  //entitySourceConnection: SaveBizSystemConnectorRequestV2;
  eventSourceConnection: {
    configuredConnection: SaveSourceConfigureConnection;
  };
  entitySourceConnection: {
    configuredConnection: SaveBizSystemConnectorRequestV2;
  };
  verticalContext: VerticalContextResponse;
  sampleSize?: number;
}

export interface InitiateAutoDiscoveryUIResponse {
  requestId: string;
  traceInfo?: TraceInfo;
  status: DiscoveryStatus; // STARTED/ERROR
  response: DiscoveryStatusResponse;
}

// Should we have a separate proto for AutoDiscoveryStatusUIRequest & AutoDiscoveryStatusRequest?
export interface AutoDiscoveryStatusUIRequest {
  // requestId returned by auto-discovery service in InitiateAutoDiscoveryUIResponse
  requestId: string;
  traceInfo?: TraceInfo;
}

export interface AutoDiscoveryStatusUIRequestV2 {
  traceInfo: TraceInfo;
  vertical: string;
  subVertical: string;
  isDemo: boolean;
  dryRun?: boolean;
}

export interface AutoDiscoveryProgressResponse {
  progressPercentage: number; // range 0 - 100
  progressDetail: DiscoveryStatusResponse;
}

export type AutoDiscoverySourceSummary = {
  sourceId: string;
  name: string;
  sourceTypeId: string;
};

export interface EntityTypeList {
  entityType: string[];
}

export interface EventTypeCriteria {
  criteria: UserServiceNamingRule;
}

export interface MappedEntities {
  mappedEntities: EntityTypeList;
  filters: SourceFilter[];
  eventTypeCriteria: EventTypeCriteria;
}

export interface MappedEventTypes {
  mappedEventTypes: Record<string, MappedEntities>;
}

export interface AutoDiscoveryUIResponse {
  entitySource: AutoDiscoverySourceSummary[];
  eventSource: AutoDiscoverySourceSummary[];
  eventSourceMapping: Record<string, MappedEventTypes>;
  entitySchemas: Record<string, any>;
}

export interface EventTypeSummary {
  eventTypeName: string;
  eventSource: string[];
  mappedEntity: string[];
  // source filter and event naming
  filters: SourceFilter[];
  //
  //EventCriteria eventCriteria = 5;
}

export interface CancelAutoDiscoverRequest {
  requestId: string;
  traceInfo?: TraceInfo;
}

export interface CancelAutoDiscoverResponse {
  // requestId passed in CancelAutoDiscoveryUIRequest
  requestId: string;
  traceInfo: TraceInfo; // traceInfo passed in CancelAutoDiscoveryUIRequest
  status: DiscoveryStatus;
  response: DiscoveryStatusResponse;
}

export interface AutoDiscoverPathParams {
  requestId: string;
  mode: "add" | "edit";
}

export interface EventCriteria {
  criteria: UserServiceNamingRule;
  filters: SourceFilter[];
}

export interface SourceSummary {
  name: string;
  sourceTypeId: string;
  sourceId: string;
  deploymentType?: string;
}

export interface EventSummary {
  eventType: string;
  displayName: string;
  icon: string;
}

export interface EventCriteria {
  criteria: UserServiceNamingRule;
  filters: SourceFilter[];
}

export interface EventGroup {
  id: string;
  eventTypes: EventSummary[];
  entityFields: EntityFields;
  eventFields: EventFields;
  eventSources: SourceSummary[];
  eventCriteria: EventCriteria;
  isVerified: boolean;
  isDeleted: boolean;
  isUnmapped: boolean;
  updateEntityFields: boolean;
  updateEventFields: boolean;
  customObjectFields: CustomObjectFields;
  updateCustomObjectFields: boolean;
}

export interface EventGroupRequest {
  requestId: string;
  traceInfo?: TraceInfo;
}

export type EventTypeResultList = {
  eventTypeResult: EventTypeResult[];
};

export type EntityTypeResultList = {
  entityTypeResult: DimensionV2[];
};

type EventTypeEntityEntry = {
  displayName: string;
  entityId: string;
  typeId: string;
};

type EventTypeEntry = {
  description: string;
  dimension: DimensionV2[];
  name: string;
  source: string;
  icon: IncFaIconName;
};

export type EventTypeResult = {
  eventType: EventTypeEntry;
  eventTypeEntity: EventTypeEntityEntry;
  isMapped: boolean;
};

export interface DataOnboardingDiscoveryRequest {
  traceId: string;
  companyName: string;
  sourceId?: string[];
  eventTypes?: EventTypeResultList;
  entityTypes?: EntityTypeResultList;
}

export type EventTypeIdentifier = {
  // one of the two is required
  name?: string;
  entityId?: string;
};

export interface GetDataModelRequest {
  traceInfo: TraceInfo;
  eventTypeIdentifier: EventTypeIdentifier[];
  filters: DataModelFilters;
}

export interface GetDataModelRequestWithUseCase {
  useCaseIds: string[];
  dataModel: GetDataModelRequest;
}

export interface GetBicycleFieldInsightRequest {
  traceInfo: TraceInfo;
  eventTypeDataModel: EventTypeDataModel;
}

export interface BicycleFieldInsightResponse {
  traceInfo: TraceInfo;
  bicycleFieldInsights: BicycleFieldInsights;
  error: string[];
}

export interface BicycleFieldInsights {
  eventType: string;
  bicycleFieldInsight: Record<string, BicycleFieldInsight>;
}

export interface BicycleFieldInsight {
  simpleDimension?: DiscoveredFieldDef;
  complexDimension?: DiscoveredEntityFieldDef;
  totalRecords: number;
  distinctValues: number;
  missing: number;
  errorCount: number;
  errorSample: TransformationResult[];
  successSample: TransformationResult[];
}

interface TransformationResult {
  propertyName?: string; // used for entity property
  rawEvent?: string;
  transformedValue: TransformedValue;
}

type DataModelFilters = {
  eventSource: string[];
};

export interface DataOnboardingDiscoveryRequestV2 {
  companyName: string;
  sourceId?: string[];
}

export interface RunOnboardingDiscoveryRequest {
  companyName: string;
  eventStream?: SaveSourceConfig;
  entityStream?: SaveBizSystemConnectorRequestV2;
  eventTypes: EventTypeResultList;
  entityTypes: EntityTypeResultList;
  traceInfo: TraceInfo;
}

export interface DataOnBoardingDiscoveryStatus {
  traceInfo: TraceInfo;
  progressPercentage: number;
  eventDiscoveryState?: EventDiscoveryState;
  entityDiscoveryState?: EntityDiscoveryState;
  dimensionDiscoveryState?: DimensionDiscoveryState;
  error?: string;
}

export interface DataOnboardingDiscoveryStatusResponseV2 {
  progressPercentage: number;
  estimatedTime: number;
  numberOfSources: number;
  eventTypeDiscoveryStatus: EventTypesDiscoveryStatus;
  isTerminated: boolean;
  error: string[];
}

interface EventTypesDiscoveryStatus {
  statusMessage: string;
  eventTypeToDiscoveryStatus: Record<string, EventTypeDiscoveryStatus>;
}

interface EventTypeDiscoveryStatus {
  eventTypeName: string;
  criteriaDiscoveryState: DiscoveryState;
  dimensionDiscoveryState: DiscoveryState;
  dimensionDiscoveryStatusMessage: string;
  sourceName: string;
  rawValue: string; // one of source name or raw value exists

  // simple dimension name to discovery status
  dimensionDiscoveryStatus: Record<string, SimpleDimensionDiscoveryStatus>;
  // complex dimension name to discovery status
  complexDimensionDiscoveryStatus: Record<string, ComplexDimensionDiscoveryStatus>;
}

interface SimpleDimensionDiscoveryStatus {
  simpleDimensionName: string;
  discoveryState: DiscoveryState;
  rawFieldName: string[];
}

interface ComplexDimensionDiscoveryStatus {
  complexDimensionName: string;
  discoveryState: DiscoveryState;
  rawFieldNameToProperty: Record<string, string>;
}

export enum DiscoveryState {
  UNKNOWN = "UNKNOWN",
  IN_PROGRESS = "IN_PROGRESS",
  DISCOVERED = "DISCOVERED",
  NOT_FOUND = "NOT_FOUND"
}

export interface EventDiscoveryState {
  eventToDiscoveryState: Record<string, DiscoveryState>;
}

export interface EntityDiscoveryState {
  entityToDiscoveryState: Record<string, DiscoveryState>;
}

export interface DimensionDiscoveryState {
  dimensionToDiscoveryState?: Record<string, DiscoveryState>;
}

export interface EventGroupList {
  eventGroups: EventGroup[];
}

export interface EventGroupResponse {
  traceInfo?: TraceInfo;
  eventGroups: Record<string, EventGroup>;
  verifiedMappingsBySource?: Record<string, EventGroupList>;
  eventTypeDetails?: Record<string, EventDetails>;
  eventSources: Record<string, SourceSummary>;
  //UI-only
  summaryNodesGroupId?: string;
}

export interface AutoDiscoveryStatusUIResponse {
  // requestId passed in AutoDiscoveryStatusUIRequest
  requestId: string;
  traceInfo?: TraceInfo; // traceInfo passed in AutoDiscoveryStatusUIRequest
  status: DiscoveryStatus;
  autoDiscoveryProgressResponse?: AutoDiscoveryProgressResponse; // for status IN_PROGRESS
  eventGroupResponse?: EventGroupResponse;
  otherResponse?: DiscoveryStatusResponse; // for any other status
}

export type EventDiscoveryStats = {
  eventToDiscoveryState: Record<string, DiscoveryStatus>;
};

export type EntityDiscoveryStats = {
  entityToDiscoveryState: Record<string, DiscoveryStatus>;
};

export interface AutoDiscoveryStatusUIResponseV2 {
  traceInfo: TraceInfo;
  progressPercentage: number;
  timeElapsed: number;
  estimatedRemainingTime: number;
  eventDiscoveryState: EventDiscoveryStats;
  entityDiscoveryState: EntityDiscoveryStats;
  viewRawDataURI: string;
}

export interface AutoDiscoveryStatus extends AutoDiscoveryStatusUIResponseV2 {}

export interface MappingGroupNode {
  mapping: EventGroup;
  height: number;
}

export interface MappingSourceNodeDetails {
  groups: EventGroup[];
  source: SourceSummary;
}

export interface VerifyMappingRequest {
  requestId: string;
  traceInfo?: TraceInfo;
  eventGroup: EventGroup;
  retainMapping?: boolean;
}

export interface VerifyMappingResponse {
  traceInfo?: TraceInfo;
  eventGroup: EventGroup;
}

export interface DeleteMappingRequest {
  requestId: string;
  traceInfo?: TraceInfo;
  eventGroupId: string;
}

export interface MappingSourcePreviewRequest {
  requestId: string;
  traceInfo?: TraceInfo;
  source: SourceSummary;
  limit: number;
}

export interface MappingSourcePreviewResponse {
  traceInfo?: TraceInfo;
  sourceDetail: ConfiguredConnectorStream;
  previewData: PreviewDataObj;
  //jsonpath to field def
  fields: Record<string, DiscoveredFieldDef>;
}

export interface MappingGroupPreviewRequest {
  requestId: string;
  traceInfo?: TraceInfo;
  eventGroup: EventGroup;
  limit: number;
  eventCriteria?: EventCriteria; // If event criteria was changed
}

export interface MappingGroupPreviewResponse {
  traceInfo?: TraceInfo;
  userServiceResponse: UserServiceNameResponse;
  //jsonpath to field def
  fields: Record<string, DiscoveredFieldDef>;
  /*
   * Updated EventGroup since EventGroup object can change with change in criteria
   * Ex. not all old event types may be available after change in filters
   */
  eventGroup: EventGroup;
  groupSchema: SourceSchema;
}

export interface MappingEventTypePreviewRequest {
  requestId: string;
  traceInfo?: TraceInfo;
  eventGroupId: string;
  eventSummary: EventSummary;
  limit?: number;
  vertical?: string;
  subVertical?: string;
}

export interface MappingEventTypePreviewResponse {
  traceInfo?: TraceInfo;
  previewData: PreviewDataObj;
  eventFields: EventFields;
  entityFields: EntityFields;
  availableFields: Record<string, DiscoveredFieldDef>;
  customObjectFields: CustomObjectFields;
  commonEventFields?: EventFields;
  commonEntityFields?: EntityFields;
  commonCustomObjectFields?: CustomObjectFields;
}

export interface CustomObjectFields {
  fields: DiscoveredCustomFieldDef[];
}

export interface EventFields {
  fields: DiscoveredFieldDef[];
  recommendedFields: VerticalEventField[];
}

export interface EntityFields {
  fields: DiscoveredEntityFieldDef[];
  // Fields which were recommended and were not matched and haven't been been matched by the user
  recommendedEntityTypes: VerticalEntityType[];
}

export interface DiscoveredEntityFieldDef {
  properties: Record<string, DiscoveredFieldDef>;
  entityType: EntityTypeSummary;
  derivedFieldName: string;
  verticalContextEntityType?: VerticalEntityType;
  isModified: boolean;
  isCommonField: boolean;
  mappingType: MappingType;
  idProperties?: string[];
  isVirtual?: boolean;
  virtualPropertySet?: string[];
  virtualTransformation?: Array<FieldTransformationConfig<TransformType>>;
  virtualEntityProperties?: Record<string, string>;
}

export interface EntityTypeList {
  entityType: string[];
}

export interface MappingSplitEventTypeRequest {
  requestId: string;
  traceInfo?: TraceInfo;
  // Present event group
  eventGroup: EventGroup;
  // Updated event criteria for the split
  eventCriteria: EventCriteria;
}

export interface MappingSplitEventTypeResponse {
  traceInfo?: TraceInfo;
  updatedEventGroup: EventGroup;
}

export interface EventDetails {
  fieldsVerified: boolean;
  entitiesVerified: boolean;
  fieldCategoryStats: FieldCategoryStats[];
  entityTypeStats: EntityTypeStats[];
}

export interface FieldCategoryStats {
  category: FieldTypeCategory;
  count: number;
}

export interface EntityTypeSummary {
  entityTypeId: string;
  icon: string;
}

export interface EntityTypeStats {
  entityType: EntityTypeSummary;
  discoveredInstances: number;
  isVerified: boolean;
}

export interface VerifyEventTypeRequest {
  requestId: string;
  traceInfo?: TraceInfo;
  eventGroupId: string;
  eventType: EventSummary;
  updateEventFields: boolean;
  eventFields: EventFields;
  updateEntityFields: boolean;
  entityFields: EntityFields;
  customObjectFields: CustomObjectFields;
  vertical?: string;
  subVertical?: string;
}

export interface DiscoveryCommonUIResponse {
  traceInfo?: TraceInfo;
  status: DiscoveryStatus;
  response: DiscoveryStatusResponse;
}

export interface DataTransformationPreviewRequest {
  requestId: string;
  traceInfo?: TraceInfo;
  previewData: PreviewDataObj;
  field: DiscoveredFieldDef;
  customObject?: DiscoveredCustomFieldDef;
  eventTypes?: string[];
  availableField?: DiscoveredFieldDef[];
  // Every field will have transformation, which will be applicable for those fields
  // fields: Record<string, DiscoveredFieldDef>;
  // these transformations will be applied on the result of transformed version of `fields`
  // transformations: Array<FieldTransformationConfig<TransformType>>;
  // transformedFieldName: string;
  // transformedFieldDataType: FieldPrimType;
  // transformedFieldSubtype: FieldSubType;
  // transformedFieldCategory: FieldTypeCategory;
}

export interface DataTransformationRequestV2 {
  connectorId: string;
  streamIds: string[];
  field: UserServiceFieldDef;
  customObject?: UserServiceFieldDef;
  eventTypes: string[];
  customFieldValue: string;
  filters: SourceFilter[];
  splitCriteriaEventName: string;
  splitCriteria: UserServiceNamingRule;
}

export interface DataTransformationPreviewResponseV2 {
  transformedFieldDef: UserServiceFieldDef;
  previewData: TransformationPreviewData;
  distribution: Distribution;
}

export interface TransformedValue {
  // jsonpath (passed as key in fields map of DataTransformationPreviewRequest) -> Raw value
  rawFieldValues: Record<string, EntityPropertyValue>;
  //one of transformedValue or error would be present
  transformedValue: EntityPropertyValue | null;
  errorInfo?: {
    errorFingerprint: string;
    errorMessage: string;
    errorStackTrace: string;
  };
  error?: string | null;
}

export interface TransformationPreviewData {
  transformedValues: TransformedValue[];
}
export interface DataTransformationPreviewResponse {
  traceInfo?: TraceInfo;
  transformedFieldDef: DiscoveredFieldDef;
  previewData: TransformationPreviewData;
}

export interface AddEventSourceRequest {
  requestId: string;
  traceInfo?: TraceInfo;
  eventGroup: EventGroup;
  newEventSources: SourceSummary[];
  limit?: number;
}

export interface AddEventGroupRequest {
  traceInfo?: TraceInfo;
  requestId: string;
  eventSummary: EventSummary;
  sources: SourceSummary[];
}

export interface FieldMappingOptionsRequest {
  traceInfo?: TraceInfo;
  eventGroup?: EventGroup;
  eventType?: string;
  eventTypeEntityId?: string;
  fieldName: string;
  fieldRecommenderType?: unknown;
}

export interface FieldMappingOptionsResponse {
  traceInfo: TraceInfo;
  discoveredFieldDef: DiscoveredFieldDef[];
}

export interface StreamList {
  streams: SourceSummary[];
}

export interface EventTypeDataModelGroup {
  eventTypes: EventTypeDataModel[];
  streams: SourceSummary[];
  sources: string[];
}

export interface DataOnBoardingDiscoveryResponse {
  eventGroup: EventTypeDataModelGroup[];
  sourceToStream: Record<string, StreamList>;
  eventTypeDetails: Record<string, EventTypeDataModel>;
}

export interface EventTypeMapping {
  mappingId: string;
  eventCriteria: EventCriteria;
  compositeSource: SourceSummary[];
  simpleDimensions: DiscoveredFieldDef[];
  complexDimensions: DiscoveredEntityFieldDef[];
  isVerified: boolean;
  hasIncrementalRecommendations: boolean;
}

export type UserServiceMappingRulesRequest = {
  ruleId: string;
  connectorStreamConfigId: string;
  userServiceNames: string[];
};

export type AvailableFieldDef = {
  fieldDef: UserServiceFieldDef;
  distribution: Distribution;
};

export type FieldStats = {
  cardinalityResponse?: {
    count: string;
    ratio: number;
    value: string;
  };
  statsResponse?: {
    count: number;
    min: number;
    max: number;
    avg: number;
    sum: number;
  };
  hasUniqueValue: boolean;
  distribution: Distribution;
};

export type AvailableFieldDefs = {
  jsonPathToAvailableFieldDef: Record<string, AvailableFieldDef>;
};

export type StatsInfo = {
  fieldValueStats: FieldStats;
  predefinedFieldTypePropToFieldValueStats: Record<string, FieldStats>;
};

export type MappedFieldStats = {
  predefinedFieldTypeToFieldValueStatsInfo: Record<string, StatsInfo>;
};

export type UserServiceMappingRuleUpdateRequest = {
  userServiceMappingRuleInfo: UserServiceMappingRuleInfo;
};

export type UserServiceMappingRulesResponse = {
  userServiceMappingRuleInfo: UserServiceMappingRuleInfo;
  userServiceToAvailableFieldDefs: Record<string, AvailableFieldDefs>;
  userServiceToMappedFieldStats: Record<string, MappedFieldStats>;
};

export type UserServiceMappingRuleInfo = {
  userServiceMappingRule: UserServiceMappingRule;
  connectorStreamConfigIds: string[];
  userServiceToIcons: Record<string, string>;
};

export interface EventTypeMappingList {
  eventTypeMappings: EventTypeMapping[];
}

export interface DataModelRecommendations {
  eventTypeDataModel: Record<string, EventTypeDataModel>;
  eventTypePreviewData: Record<string, PreviewDataObj>;
  complexDimension: Record<string, DiscoveredEntityFieldDef>;
  eventTypeDataModels: Record<string, EventTypeDataModelList>;
}

type EventTypeDataModelList = {
  eventTypeDataModel: EventTypeDataModel[];
};

export interface DataModelResponse {
  traceInfo: TraceInfo;
  dataModel: DataModelRecommendations;
}

export interface VerifyEventTypeDataModel {
  traceInfo: TraceInfo;
  eventTypeDataModel: EventTypeDataModel[];
  vertical: VerticalIdentifier;
}

export interface EventTypeDataModelV2 {
  compositeSource: SourceSummary[];
  eventTypeMapping: EventTypeMappingV2[];
  splitCriteria: UserServiceNamingRule;
  overallFilters: SourceFilter[];
}

export interface EventTypeMappingV2 {
  mappingId: string;
  name: string;
  displayName: string;
  icon: string;
  mappingFilters: SourceFilter[];
  simpleDimensions: DiscoveredFieldDef[];
  complexDimensions: DiscoveredEntityFieldDef[];
  isVerified: boolean;
  jsonPathToAvailableField: Record<string, DiscoveredFieldDef>;
}

export interface VerticalIdentifier {
  vertical: string;
  subVertical: string;
  companyName: string;
}

export interface EventTypeDataModel {
  name: string;
  displayName: string;
  eventTypeMapping: EventTypeMapping;
  jsonPathToAvailableField: Record<string, DiscoveredFieldDef>;
  icon?: IncFaIconName;
}

export enum DataUploadType {
  SANITY_CHECK = "SANITY_CHECK",
  UPLOAD = "UPLOAD"
}

export interface DataUploadStatusRequest {
  traceInfo: TraceInfo;
  eventSource: SourceSummary[];
  uploadType: DataUploadType;
}

export type UploadDataRequest = Omit<DataUploadStatusRequest, "uploadType">;

enum DataUploadStatusDataType {
  BATCHED = "BATCHED",
  STREAM = "STREAM"
}

export type DataUploadStatus = {
  status: DiscoveryStatus;
  progress: number;
  message: string;
  error: string[];
  dataType: DataUploadStatusDataType;
  errors: Record<string, UploadStatusErrorList>;
  totalRows: number;
  processedRows: number;
  uploadStartTimeInMillis: number;
  uploadEndTimeInMillis: number;
  jobId: string;
  failedRows: number;
};

export type UploadStatusErrorList = {
  uploadStatusErrors: UploadStatusError[];
};

type UploadStatusError = {
  rawEventIdentifier: string;
  errorMessage: string;
};

export interface DataUploadStatusResponse {
  traceInfo: TraceInfo;
  uploadType: DataUploadType;
  eventSourceDataUploadStatus: Record<string, DataUploadStatus>;
}

export interface BicycleEventPreviewRequestV2 {
  traceInfo: TraceInfo;
  dataModel: EventTypeDataModel;
}

export interface PreviewBicycleEvent {
  rawEvent: string[];
  transformedFieldDef: DiscoveredFieldDef[];
  transformedEntityFieldDef: DiscoveredFieldDef[];
}

export interface BicycleEventPreviewResponseV2 {
  previewBicycleEvents: PreviewBicycleEvent[];
  scrollId: string;
  error: string[];
}

export type MappingWorkflowOutput = {
  traceInfo: TraceInfo;
  planExecutionResponse: PlanExecutionResponse;
  status: MappingWorkflowStatus;
  startTimeMillis: number;
  endTimeMillis: number;
  totalTimeTaken: number;
  eventMappingResponse: UserServiceMappingRulesResponse[];
  entityMappingResponse: UserServiceMappingRulesResponse[]; // View only specific, to be removed later
  questionFragments?: QuestionFragment[];
};

export type MappingWorkflowQuestionResponsePayload = {
  runId: string;
  questionFragments: QuestionFragment[];
};

export enum MappingWorkflowStatus {
  "NA" = "NA",
  "COMPLETED" = "COMPLETED",
  "ONGOING" = "ONGOING",
  "ERROR" = "ERROR",
  "QUESTION" = "QUESTION"
}

export type PreviewDataRequestV3 = {
  // one of these must be set
  connectorSourceId?: string;
  streamDetails?: ConfiguredConnectorStream;

  limit: number;
  previewFilters: SourceFilter[];
  traceInfo: TraceInfo;

  sourceFieldMapping?: SourceFieldMapping;
};
