import { UITenantConfig } from "../../../src/platform/core/hooks/tenant-config";
import { SchemaTypesResponse } from "../../../src/platform/services/api/SchemaApiService";
import { AllowedOrgsApiResponse, BootConfigPayload } from "../../../src/platform/services/api";
import { EntityTypeWrapper } from "../../../src/platform/services/api/types";
import { getBizEntityUrl } from "./utils";

export const ApplicationConstants = {
  attributes: {
    incTab: "inc-tab",
    modalCloseIcon: "modal-close-icon",
    impactSummaryContent: "impact-summary-content",
    radioButton: "radio-button",
    modalPrimaryAction: "modal-primary-action",
    ResponseRendererOptionPill: "response-renderer-option-pill",
    logo: "sidemenu-logo"
  },
  useCaseUrls: {
    fetchCompanyInfos: getBizEntityUrl("usecaseCatalog/fetch-company-infos"),
    getCompanyInfo: getBizEntityUrl("usecaseCatalog/get-company-info?*"),
    listUseCaseCatalog: getBizEntityUrl("usecaseCatalog/list?*"),
    memberUseCases: getBizEntityUrl("usecase/member?*"),
    fetchUseCase: getBizEntityUrl("usecase/*"),
    fetchFeed: getBizEntityUrl("usecase/user/feed")
  },
  postLoginUrls: {
    bootConfig: "/ui/api/boot_config",
    tenantConfig: "/api-proxy/api/tenants/current/config/ui/tenant_config",
    defaultTimeZone: "/api-proxy/api/tenants/current/config/defaultTimeZone",
    entityTypeCacheInit: "/api-proxy/schema-store/api/v1/types?typeId=_entity"
  },
  mockResponses: {
    "/ui/api/boot_config": {
      body: {
        user: {
          id: 18,
          email: "test-user@domain.com",
          name: "aditya",
          login: "test-user@domain.com",
          theme: "",
          orgId: 47,
          isGrafanaAdmin: false,
          isDisabled: false,
          isExternal: false,
          authLabels: [],
          updatedAt: "2024-02-17T09:33:25Z",
          createdAt: "2021-02-03T13:43:24Z",
          avatarUrl: "/apptuit/public/img/user_profile.png",
          role: "Admin",
          lastSeen: "1713368105000"
        },
        org: {
          id: 47,
          name: "Cypress Tests Org",
          address: {
            address1: "",
            address2: "",
            city: "",
            zipCode: "",
            state: "",
            country: ""
          }
        },
        isSupportSession: false,
        supportContext: null,
        authType: {
          name: "password",
          authType: "password",
          authSubType: null,
          config: null,
          authStrategy: null,
          allowSupportAccess: null,
          allowViewerAccess: null,
          emailDomain: null,
          orgId: "47",
          tenantId: "cypress_tests_org",
          tenantInfo: {
            tenantId: "cypress_tests_org",
            orgId: "47",
            status: "ACTIVE",
            orgName: "Cypress Tests Org"
          }
        },
        isSupportLoginEnabled: true,
        userOrgs: [
          {
            orgId: 2,
            name: "Test Org1",
            role: "Editor"
          },
          {
            orgId: 31,
            name: "Test Org2",
            role: "Editor"
          },
          {
            orgId: 24,
            name: "Test Org3",
            role: "Editor"
          }
        ]
      } as unknown as BootConfigPayload,
      statusCode: 200
    },
    "/api-proxy/api/tenants/current/config/ui/tenant_config": {
      body: {
        demoTenant: true,
        leftNavPreset: [
          "home",
          "messages",
          "operationalizeHome",
          "dashboard",
          "events",
          "alerts",
          "insights",
          "businessCatalog",
          "dataSettings",
          "knowledgeBaseSettings",
          "memberSettings",
          "businessEntities",
          "admin",
          "demoBuilder",
          "companySelector"
        ],
        defaultDrilldownVersion: "v5",
        enableNewDashboardFilterPanel: true,
        enableCauseSummaryNlp: true,
        enableDiscoverySandbox: true,
        enableNewTriageSummary: true,
        enableKpiBasedOnboarding: true,
        enableRecipeInOp10zePreview: true,
        enableDashboardShare: true,
        enableAddEntityFilter: true,
        enablePartnerFeatures: false,
        enableChatInterfaceForAddOp10ze: true,
        customTimeRanges: [
          {
            id: "9MpOcfZAj",
            label: "Last 30 days",
            from: "now-30d",
            to: "now",
            isDefault: false
          }
        ]
      } as unknown as UITenantConfig,
      statusCode: 200
    },
    "/api-proxy/schema-store/api/v1/types?typeId=_entity": {
      body: {
        types: [
          {
            entityType: {
              typeReference: {
                id: "i_cohort",
                typeName: "Cohort",
                desc: "Cohort"
              },
              idPropSet: [
                {
                  prop: ["id"]
                }
              ],
              propertiesMeta: {
                user_labels: {
                  kind: "_map",
                  indexed: true
                },
                i__missing_rels: {
                  kind: "_set",
                  indexed: true
                },
                i_metadata: {
                  kind: "_map",
                  indexed: true
                },
                id: {
                  versioned: true,
                  indexed: true
                },
                name: {
                  versioned: true,
                  indexed: true
                },
                memberTypeId: {
                  versioned: true,
                  indexed: true
                },
                Name: {
                  indexed: true
                },
                draft: {
                  kind: "_bool"
                },
                sourceCohortId: {
                  versioned: true
                }
              },
              maxVersions: 10,
              indexGroup: "default",
              propertyMapping: {
                user_labels: {
                  kind: "_map",
                  columnId: "ten111.default.idx.set0"
                },
                i__missing_rels: {
                  kind: "_set",
                  columnId: "ten111.default.idx.set1"
                },
                i_metadata: {
                  kind: "_map",
                  columnId: "ten111.default.idx.set2"
                },
                id: {
                  columnId: "ten111.default.idx.str0"
                },
                name: {
                  columnId: "ten111.default.idx.str1"
                },
                memberTypeId: {
                  columnId: "ten111.default.idx.str2"
                },
                Name: {
                  columnId: "ten111.default.idx.str3"
                },
                draft: {
                  kind: "_bool",
                  columnId: "ten111.global.col.bool0"
                },
                sourceCohortId: {
                  columnId: "ten111.global.col.str0"
                }
              },
              revPropMapping: {
                "ten111.default.idx.set0": "user_labels",
                "ten111.default.idx.set1": "i__missing_rels",
                "ten111.default.idx.set2": "i_metadata",
                "ten111.default.idx.str0": "id",
                "ten111.default.idx.str1": "name",
                "ten111.default.idx.str2": "memberTypeId",
                "ten111.default.idx.str3": "Name",
                "ten111.global.col.bool0": "draft",
                "ten111.global.col.str0": "sourceCohortId"
              }
            }
          }
        ]
      } as unknown as SchemaTypesResponse<EntityTypeWrapper>,
      statusCode: 200
    },
    "ui/api/list_switch_orgs": {
      body: {
        allowedOrgs: [
          {
            orgId: 47,
            name: "Cypress Tests Org",
            role: "Viewer",
            allowSwitch: true,
            allowSupport: false,
            allowedSupportRoles: []
          }
        ]
      } as unknown as AllowedOrgsApiResponse,
      statusCode: 200
    }
  }
};
