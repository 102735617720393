export const CohortConfig = {
  btnValueSelector: (label: string) => `inc-button-group-${label}`,
  fieldValueSelector: "field-value-selector",
  fieldValueSelectorValueSelector: (label: string) => `field-value-selector-data-selector-${label}`,
  fieldValueSelectorSubmit: "field-value-selector-submit",
  mockRequests: {
    "api-proxy/api/bizEntity/City/filters/fields/Name/values?**": {
      values: [
        "North Carolina",
        "Indiana",
        "Salvador",
        "Zurich",
        "Utah",
        "Curitiba",
        "Belo Horizonte",
        "Arizona",
        "Montana",
        "Calgary",
        "Kentucky",
        "Piraeus",
        "Manaus",
        "Kolkata",
        "California",
        "Kansas",
        "Florida",
        "Pennsylvania",
        "New Delhi",
        "Iowa",
        "Illinois",
        "Texas",
        "Connecticut",
        "London",
        "Georgia",
        "Virginia",
        "Maryland",
        "Toronto",
        "Idaho",
        "Ahmedabad",
        "Oregon",
        "Vancouver",
        "Maine",
        "Tennessee",
        "Oklahoma",
        "Geneva",
        "Alabama",
        "Mumbai",
        "Washington",
        "South Carolina",
        "Nebraska",
        "West Virginia",
        "Massachusetts",
        "Colorado",
        "Missouri",
        "Alaska",
        "Edinburgh",
        "Wisconsin",
        "Nevada",
        "New York",
        "District of Columbia",
        "South Dakota",
        "Hawaii",
        "Minnesota",
        "Rio de Janeiro",
        "New Jersey",
        "Michigan",
        "Neywork",
        "New Hampshire",
        "Louisiana",
        "Ohio",
        "Montreal"
      ],
      entityLookupData: {}
    },
    "/api-proxy/api/bizEntity/City/filters/fields/values?**": {
      body: {
        entityType: "City",
        aggregations: {},
        suggestedAggregations: {
          Name: {
            field: "Name",
            kind: "_str",
            suggestedAggregationOperator: {},
            aggregationMeta: {
              cardinality: {
                value: "0",
                ratio: 1
              }
            }
          }
        }
      }
    },
    "/api-proxy/api/bizEntity/Merchant/filters/fields/values?**": {
      body: {
        entityType: "Merchant",
        aggregations: {
          City: {
            topn: {
              buckets: [
                {
                  key: "New York",
                  count: "184",
                  from: 0,
                  to: 0,
                  label: ""
                },
                {
                  key: "California",
                  count: "177",
                  from: 0,
                  to: 0,
                  label: ""
                },
                {
                  key: "Florida",
                  count: "155",
                  from: 0,
                  to: 0,
                  label: ""
                },
                {
                  key: "Texas",
                  count: "147",
                  from: 0,
                  to: 0,
                  label: ""
                },
                {
                  key: "Arizona",
                  count: "129",
                  from: 0,
                  to: 0,
                  label: ""
                },
                {
                  key: "London",
                  count: "114",
                  from: 0,
                  to: 0,
                  label: ""
                },
                {
                  key: "Toronto",
                  count: "105",
                  from: 0,
                  to: 0,
                  label: ""
                },
                {
                  key: "New Jersey",
                  count: "95",
                  from: 0,
                  to: 0,
                  label: ""
                },
                {
                  key: "Vancouver",
                  count: "95",
                  from: 0,
                  to: 0,
                  label: ""
                },
                {
                  key: "Illinois",
                  count: "81",
                  from: 0,
                  to: 0,
                  label: ""
                },
                {
                  key: "Tennessee",
                  count: "72",
                  from: 0,
                  to: 0,
                  label: ""
                },
                {
                  key: "Georgia",
                  count: "68",
                  from: 0,
                  to: 0,
                  label: ""
                },
                {
                  key: "North Carolina",
                  count: "46",
                  from: 0,
                  to: 0,
                  label: ""
                },
                {
                  key: "Salvador",
                  count: "44",
                  from: 0,
                  to: 0,
                  label: ""
                },
                {
                  key: "Washington",
                  count: "42",
                  from: 0,
                  to: 0,
                  label: ""
                },
                {
                  key: "Curitiba",
                  count: "32",
                  from: 0,
                  to: 0,
                  label: ""
                },
                {
                  key: "Rio de Janeiro",
                  count: "32",
                  from: 0,
                  to: 0,
                  label: ""
                },
                {
                  key: "Louisiana",
                  count: "30",
                  from: 0,
                  to: 0,
                  label: ""
                },
                {
                  key: "Michigan",
                  count: "29",
                  from: 0,
                  to: 0,
                  label: ""
                },
                {
                  key: "Belo Horizonte",
                  count: "27",
                  from: 0,
                  to: 0,
                  label: ""
                },
                {
                  key: "Zurich",
                  count: "27",
                  from: 0,
                  to: 0,
                  label: ""
                },
                {
                  key: "Mumbai",
                  count: "23",
                  from: 0,
                  to: 0,
                  label: ""
                },
                {
                  key: "New Delhi",
                  count: "23",
                  from: 0,
                  to: 0,
                  label: ""
                },
                {
                  key: "Nevada",
                  count: "22",
                  from: 0,
                  to: 0,
                  label: ""
                },
                {
                  key: "Calgary",
                  count: "20",
                  from: 0,
                  to: 0,
                  label: ""
                },
                {
                  key: "Colorado",
                  count: "20",
                  from: 0,
                  to: 0,
                  label: ""
                },
                {
                  key: "Wisconsin",
                  count: "20",
                  from: 0,
                  to: 0,
                  label: ""
                },
                {
                  key: "Connecticut",
                  count: "19",
                  from: 0,
                  to: 0,
                  label: ""
                },
                {
                  key: "Geneva",
                  count: "17",
                  from: 0,
                  to: 0,
                  label: ""
                },
                {
                  key: "Montana",
                  count: "17",
                  from: 0,
                  to: 0,
                  label: ""
                },
                {
                  key: "Virginia",
                  count: "15",
                  from: 0,
                  to: 0,
                  label: ""
                },
                {
                  key: "Utah",
                  count: "14",
                  from: 0,
                  to: 0,
                  label: ""
                },
                {
                  key: "Edinburgh",
                  count: "13",
                  from: 0,
                  to: 0,
                  label: ""
                },
                {
                  key: "Montreal",
                  count: "12",
                  from: 0,
                  to: 0,
                  label: ""
                },
                {
                  key: "Ahmedabad",
                  count: "11",
                  from: 0,
                  to: 0,
                  label: ""
                },
                {
                  key: "Massachusetts",
                  count: "11",
                  from: 0,
                  to: 0,
                  label: ""
                },
                {
                  key: "Manaus",
                  count: "9",
                  from: 0,
                  to: 0,
                  label: ""
                },
                {
                  key: "Pennsylvania",
                  count: "9",
                  from: 0,
                  to: 0,
                  label: ""
                },
                {
                  key: "Ohio",
                  count: "7",
                  from: 0,
                  to: 0,
                  label: ""
                },
                {
                  key: "Oregon",
                  count: "7",
                  from: 0,
                  to: 0,
                  label: ""
                },
                {
                  key: "Minnesota",
                  count: "6",
                  from: 0,
                  to: 0,
                  label: ""
                },
                {
                  key: "Missouri",
                  count: "6",
                  from: 0,
                  to: 0,
                  label: ""
                },
                {
                  key: "Maryland",
                  count: "5",
                  from: 0,
                  to: 0,
                  label: ""
                },
                {
                  key: "Kentucky",
                  count: "4",
                  from: 0,
                  to: 0,
                  label: ""
                },
                {
                  key: "Nebraska",
                  count: "4",
                  from: 0,
                  to: 0,
                  label: ""
                },
                {
                  key: "Idaho",
                  count: "3",
                  from: 0,
                  to: 0,
                  label: ""
                },
                {
                  key: "Indiana",
                  count: "3",
                  from: 0,
                  to: 0,
                  label: ""
                },
                {
                  key: "Kolkata",
                  count: "3",
                  from: 0,
                  to: 0,
                  label: ""
                },
                {
                  key: "Oklahoma",
                  count: "3",
                  from: 0,
                  to: 0,
                  label: ""
                },
                {
                  key: "Alabama",
                  count: "2",
                  from: 0,
                  to: 0,
                  label: ""
                },
                {
                  key: "Hawaii",
                  count: "2",
                  from: 0,
                  to: 0,
                  label: ""
                },
                {
                  key: "Kansas",
                  count: "2",
                  from: 0,
                  to: 0,
                  label: ""
                },
                {
                  key: "New Hampshire",
                  count: "2",
                  from: 0,
                  to: 0,
                  label: ""
                },
                {
                  key: "South Carolina",
                  count: "2",
                  from: 0,
                  to: 0,
                  label: ""
                },
                {
                  key: "South Dakota",
                  count: "2",
                  from: 0,
                  to: 0,
                  label: ""
                },
                {
                  key: "Alaska",
                  count: "1",
                  from: 0,
                  to: 0,
                  label: ""
                },
                {
                  key: "District of Columbia",
                  count: "1",
                  from: 0,
                  to: 0,
                  label: ""
                },
                {
                  key: "Iowa",
                  count: "1",
                  from: 0,
                  to: 0,
                  label: ""
                },
                {
                  key: "Maine",
                  count: "1",
                  from: 0,
                  to: 0,
                  label: ""
                },
                {
                  key: "Piraeus",
                  count: "1",
                  from: 0,
                  to: 0,
                  label: ""
                },
                {
                  key: "West Virginia",
                  count: "1",
                  from: 0,
                  to: 0,
                  label: ""
                }
              ],
              remaining: "0"
            }
          }
        },
        suggestedAggregations: {
          City: {
            kind: "_str",
            field: "City",
            description: "",
            aggregationMeta: {
              cardinality: {
                value: "61",
                ratio: 0.024110671936758893
              }
            },
            suggestedAggregationOperator: {
              topn: {
                ascending: false,
                size: 0
              }
            }
          }
        }
      }
    }
  },
  mockRequestsForCountry: {
    "/api-proxy/api/bizEntity/Merchant/filters/fields/values?**": {
      entityType: "Merchant",
      aggregations: {
        Country: {
          topn: {
            buckets: [
              {
                key: "USA",
                count: "1467",
                from: 0,
                to: 0,
                label: ""
              },
              {
                key: "Canada",
                count: "232",
                from: 0,
                to: 0,
                label: ""
              },
              {
                key: "Brazil",
                count: "144",
                from: 0,
                to: 0,
                label: ""
              },
              {
                key: "United Kingdom",
                count: "127",
                from: 0,
                to: 0,
                label: ""
              },
              {
                key: "India",
                count: "60",
                from: 0,
                to: 0,
                label: ""
              },
              {
                key: "Switzerland",
                count: "45",
                from: 0,
                to: 0,
                label: ""
              }
            ],
            remaining: "0"
          }
        }
      },
      suggestedAggregations: {
        Country: {
          kind: "_str",
          field: "Country",
          description: "",
          aggregationMeta: {
            cardinality: {
              value: "6",
              ratio: 0.002370604504148558
            }
          },
          suggestedAggregationOperator: {
            topn: {
              ascending: false,
              size: 0
            }
          }
        }
      }
    }
  }
};
