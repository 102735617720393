import Fuse, { FuseResult, FuseOptionKey, FuseGetFunction, IFuseOptions } from "fuse.js";
import { isNil } from "lodash";

type SearchRecord = Record<string, any>;

// type ScoringFn = (matches: readonly FuseResultMatch[]) => number;
type SortFn = (a: { score: number }, b: { score: number }) => number;
type FuseKey<T> = FuseOptionKey<T>;
type FuseGetFn<T> = FuseGetFunction<T>;

export interface FuseOptions<T extends SearchRecord> extends Omit<IFuseOptions<T>, "keys" | "getFn"> {
  keys?: Array<FuseKey<T>>;
  getFn?: FuseGetFn<T>;
  sortFn?: SortFn;
}

interface FuseUtilsResult<T extends SearchRecord> {
  fuse: Fuse<T>;
  search: (pattern: string) => Array<FuseResult<T>>;
  setCollection: (items: T[]) => void;
}

export function getFuseUtils<T extends SearchRecord>(data: T[], options: FuseOptions<T>): FuseUtilsResult<T> {
  // Create Fuse instance with provided options
  const fuse = new Fuse<T>(data, {
    // Search options
    isCaseSensitive: !isNil(options.isCaseSensitive) ? options.isCaseSensitive : false,
    includeScore: true,
    includeMatches: false,
    shouldSort: !isNil(options.shouldSort) ? options.shouldSort : true,
    findAllMatches: true,
    keys: options.keys || [],
    location: 0,
    threshold: 0.6,
    distance: 100,
    minMatchCharLength: 2,

    // Scoring options
    fieldNormWeight: 1,
    getFn: options.getFn,
    sortFn: options.sortFn
  });

  // Basic search function
  const search = (pattern: string) => {
    const results = fuse.search(pattern);
    return results.sort((a, b) => (a.score || 0) - (b.score || 0));
  };

  const setCollection = (items: T[]) => {
    fuse.setCollection(items);
  };

  return {
    fuse,
    search,
    setCollection
  };
}
