import { useCallback, useMemo, useRef, useState } from "react";
import { useRefState } from "./useRefState";
import { useForceUpdate } from "./useForceUpdate";

export type PollResult<T> = {
  data: T;
  error: boolean;
  message: string;
};

export type PollCallback<T> = () => Promise<PollResult<T>>;

export type OnPollResultCallBack<T> = (data: T) => {
  isComplete: boolean;
  nextCallback?: PollCallback<T>;
};

type Props<T> = {
  /** Callback function to be polled */
  callback: PollCallback<T>;
  /** Handler for processing poll results */
  onResult: OnPollResultCallBack<T>;
  /** Interval between polls in milliseconds */
  pollIntervalMs: number;
  /**
   * Maximum duration to wait for poll completion in milliseconds.
   * We return error response when poll starts and doesn't complete within this timeout.
   * @default 120000 (2 minutes)
   */
  pollTimeoutMs?: number;
  /**
   * Custom response to be returned when polling times out
   * @default { data: null, error: true, message: "Operation timed out after X seconds" }
   */
  pollTimeoutResponse?: PollResult<T>;
};

export const usePollForStatus = <T,>(
  props: Props<T>
): {
  data: T;
  error: boolean;
  message: string;
  startPolling: () => void;
  stopPolling: () => void;
  isPolling: boolean;
  duration: number;
} => {
  const { callback, onResult, pollIntervalMs, pollTimeoutMs = 0, pollTimeoutResponse } = props;

  const timeoutRef = useRef<number>();
  const pollingStoppedRef = useRef(false);
  const isPollingRef = useRef(false);
  const startTimeRef = useRef<number>(0);

  const forceUpdate = useForceUpdate();

  const [response, setResponse] = useState<PollResult<T>>({
    data: null as T,
    error: false,
    message: ""
  });

  const callbackRef = useRefState(callback);

  const stopPolling = useCallback(() => {
    isPollingRef.current = false;
    pollingStoppedRef.current = true;
    if (timeoutRef.current) {
      window.clearTimeout(timeoutRef.current);
    }
    setResponse(prev => ({ ...prev }));
  }, []);

  const getPollTimeOutStatus = useCallback(() => {
    if (!pollTimeoutMs) {
      return false;
    }

    const hasExceededDuration = Date.now() - startTimeRef.current > pollTimeoutMs;
    if (hasExceededDuration) {
      stopPolling();
      setResponse(
        pollTimeoutResponse ?? {
          data: null as T,
          error: true,
          message: `Operation timed out after ${pollTimeoutMs} milliseconds.`
        }
      );
      console.warn(
        "usePollForStatus",
        `Operation timed out after ${pollTimeoutMs} milliseconds, using timeout response`
      );
      return true;
    }

    return false;
  }, [pollTimeoutMs, pollTimeoutResponse, stopPolling]);

  const poll = useCallback(async () => {
    const pollTimedOut = getPollTimeOutStatus();
    if (pollTimedOut) {
      return;
    }

    const response = await callbackRef.current();
    const { isComplete, nextCallback = callbackRef.current } = onResult(response.data);
    callbackRef.current = nextCallback;

    if (!isComplete) {
      timeoutRef.current = window.setTimeout(() => {
        if (!pollingStoppedRef.current) {
          poll();
        }
      }, pollIntervalMs);
    } else {
      isPollingRef.current = false;
      pollingStoppedRef.current = true;
    }
    setResponse(response);
  }, [callbackRef, onResult, pollIntervalMs, getPollTimeOutStatus]);

  const startPolling = useCallback(() => {
    if (timeoutRef.current) {
      window.clearTimeout(timeoutRef.current);
    }
    isPollingRef.current = true;
    pollingStoppedRef.current = false;
    startTimeRef.current = Date.now();
    forceUpdate();
    poll();
  }, [forceUpdate, poll]);

  const pollingStopped = pollingStoppedRef.current;
  useMemo(() => {
    if (pollingStopped) {
      callbackRef.current = callback;
    }
  }, [callback, callbackRef, pollingStopped]);

  return {
    ...response,
    startPolling,
    stopPolling,
    isPolling: isPollingRef.current,
    duration: startTimeRef.current ? Date.now() - startTimeRef.current : 0
  };
};
