import React, { useCallback, useState, useEffect, useMemo, CSSProperties } from "react";
import { ActionMeta, InputActionMeta, OptionsType, ValueType } from "react-select";
import { IncSelect as InceptionSelect, IncSelectProps } from "../Select/InceptionSelect";
import { getInceptionTheme } from "../../themes/ThemeProvider";
import { SelectionOption } from "./types";
import { IncMultiSelectValueContainerRenderer, IncMultiSelectOptionRenderer } from "./MultiSelectComponents";

interface IncMultiSelectCheckBoxProps {
  options: SelectionOption[];
  defaultOptions?: SelectionOption[];
  onChange: (selectedOptions: SelectionOption[], id?: string, isAllSelected?: boolean) => void;
  components?: IncSelectProps<SelectionOption, true>["components"];

  // Width for the container
  width?: number | string;
  // Identifier when rendering multiple instances in one component
  id?: string;
  // to show options always
  menuIsOpen?: boolean;
  autoFocus?: boolean;
  wrapperClass?: string;

  // The label to be shown for the component. Empty string will hide the label.
  label?: string;
  disablePopper?: boolean;
  placeholder?: string;
  renderInCard?: boolean;
  disabled?: boolean;
  allowCreate?: boolean;
  skipAllOption?: boolean;
  hideOptionsCount?: boolean;
  showSelectedOptions?: boolean;
  showAsPills?: boolean;
  className?: string;
  isLoading?: boolean;
  errorText?: string;
  hasError?: boolean;
  autoAdjustWidth?: boolean;
  isClearable?: boolean;
}

const filterAllFromOptions = (options: SelectionOption[]): [SelectionOption[], boolean] => {
  const selections: SelectionOption[] = [];
  let isAllSelected = false;
  options.forEach(so => {
    if (!isAllValue(so.value)) {
      selections.push(so);
    } else {
      isAllSelected = true;
    }
  });
  return [selections, isAllSelected];
};

function IncMultiSelectCheckBox(props: IncMultiSelectCheckBoxProps) {
  const {
    options,
    defaultOptions,
    onChange,
    width,
    label = "",
    menuIsOpen,
    autoFocus = false,
    disablePopper = false,
    placeholder = "",
    renderInCard = false,
    disabled = false,
    allowCreate = false,
    skipAllOption,
    wrapperClass,
    hideOptionsCount,
    showAsPills = false,
    showSelectedOptions = false,
    isLoading = false,
    className,
    errorText = "",
    hasError = false,
    autoAdjustWidth = false,
    isClearable = false,
    components: pComponents = {}
  } = props;

  const [selectedOptions, setSelectedOptions] = useState<ValueType<SelectionOption, true>>(defaultOptions || []);
  const [searchText, setSearchText] = useState<string>("");
  const [allOptions, setAllOptions] = useState<OptionsType<SelectionOption>>([]);

  useEffect(() => {
    const allOpts = options.filter(opt => Boolean(opt.label));
    const sortedOpts = allOpts.sort((a, b) => a.label.localeCompare(b.label));

    if (!skipAllOption) {
      sortedOpts.unshift({
        label: ALL_OPTION_LABEL,
        value: ALL_OPTION_VALUE
      });
    }

    setAllOptions(sortedOpts);
  }, [options, skipAllOption]);

  useEffect(() => {
    if (defaultOptions?.length === allOptions.length - 1 && !skipAllOption) {
      setSelectedOptions(allOptions);
    } else {
      setSelectedOptions(defaultOptions || []);
    }
  }, [defaultOptions, allOptions, skipAllOption]);

  const onSelectionChange = useCallback(
    (value: OptionsType<SelectionOption>, event: ActionMeta<SelectionOption>) => {
      // The type can be either SelectOptionActionMeta OR DeselectOptionActionMeta
      // Since we are checking for both in if-else below its difficult to
      // type it properly
      const { action, option } = event as any;

      const isCurrValueAll = isAllValue(option?.value || "");
      const isClearAction = action === "clear";
      const isSelectAction = action === "select-option";
      const isDeselectAction = action === "deselect-option";
      const isCreateAction = action === "create-option";

      const allDeselected = isDeselectAction && isCurrValueAll;
      const selectNone = isClearAction || allDeselected;

      const numOpts = allOptions.length;
      const numSelected = value.length;

      const allSelected = isSelectAction && isCurrValueAll;
      const allOptsSelected = isSelectAction && (skipAllOption ? numSelected === numOpts : numSelected === numOpts);
      const selectAll = allOptsSelected || allSelected;

      const selectedOptions: SelectionOption[] = selectNone
        ? []
        : selectAll
          ? [...allOptions]
          : isDeselectAction
            ? value.filter((o: any) => !isAllValue(o.value))
            : [...value];

      setSelectedOptions(selectedOptions);
      if (isCreateAction) {
        const [selections, isAllSelected] = filterAllFromOptions(selectedOptions);
        onChange(selections, props.id, isAllSelected);
      }
    },
    [allOptions, onChange, props.id, skipAllOption]
  );

  const onMenuClose = useCallback(() => {
    if (selectedOptions) {
      const [selections, isAllSelected] = filterAllFromOptions(selectedOptions as SelectionOption[]);
      onChange(selections, props.id, isAllSelected);
    }
  }, [selectedOptions, onChange, props.id]);

  const onInputChange = useCallback(
    (newValue: string, actionMeta: InputActionMeta) => {
      if (actionMeta.action !== "set-value") {
        setSearchText(newValue);
        return newValue;
      }

      return searchText;
    },
    [searchText]
  );

  const style = useMemo<CSSProperties>(
    () => ({
      width: width || DEFAULT_WIDTH,
      right: 0
    }),
    [width]
  );

  const selectStyles = useMemo<IncSelectProps<SelectionOption, true>["styles"]>(() => {
    const border = `1px solid ${getInceptionTheme().inceptionColors.palette.N100}`;

    return {
      placeholder: base => ({
        ...base,
        position: "absolute",
        right: 10
      }),
      indicatorsContainer: base => ({
        ...base,
        visibility: menuIsOpen ? "hidden" : "visible"
      }),
      menu: base => ({
        ...base,
        position: renderInCard ? "relative" : "absolute",
        border: renderInCard ? "none" : border,
        boxShadow: renderInCard ? "none" : base.boxShadow
      })
    };
  }, [menuIsOpen, renderInCard]);

  const ValueContainerInternal = useCallback(
    vProps => (
      <IncMultiSelectValueContainerRenderer
        {...vProps}
        hideOptionsCount={hideOptionsCount}
        showAsPills={showAsPills}
        showSelectedOptions={showSelectedOptions}
      />
    ),
    [hideOptionsCount, showAsPills, showSelectedOptions]
  );

  const components = {
    Option: IncMultiSelectOptionRenderer,
    ValueContainer: ValueContainerInternal,
    ...pComponents
  };

  const autoAdjustWidthBuffer = useMemo(() => 30 * (defaultOptions?.length || 1), [defaultOptions?.length]);

  return (
    <div
      className={className}
      style={style}
    >
      <InceptionSelect<SelectionOption, true>
        allowCreate={allowCreate}
        autoAdjustWidth={autoAdjustWidth}
        autoAdjustWidthBuffer={autoAdjustWidthBuffer}
        autoFocus={autoFocus}
        autoSort={false}
        className="react-select-container"
        classNamePrefix={"inc-multi-select-checkbox"}
        closeMenuOnSelect={false}
        components={components}
        data-cy={"inc-multi-select-checkbox"}
        disablePopper={disablePopper}
        errorText={errorText}
        hasError={hasError}
        hideSelectedOptions={false}
        inputValue={searchText}
        isClearable={isClearable}
        isDisabled={disabled}
        isLoading={isLoading}
        isMulti
        label={label}
        menuIsOpen={menuIsOpen}
        menuPlacement="auto"
        menuShouldScrollIntoView={false}
        name="inception-multi-select"
        onChange={onSelectionChange}
        onInputChange={onInputChange}
        onMenuClose={onMenuClose}
        options={allOptions}
        placeholder={placeholder}
        styles={selectStyles}
        value={selectedOptions}
        wrapperClass={wrapperClass}
      />
    </div>
  );
}

export default IncMultiSelectCheckBox;

const ALL_OPTION_LABEL = "All";
const ALL_OPTION_VALUE = "$__all";
const DEFAULT_WIDTH = "100%";

const isAllValue = (value: string) => value === ALL_OPTION_VALUE;
